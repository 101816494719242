import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-use';
import Spinner from '../cpt/EnchantQuiz/cpt/Spinner';

// TODO: Some sensible non-content here. Nontent.

function Index() {
  const { hostname } = useLocation();

  console.log({ hostname });

  // const [{ quiz }, dispatch] = useStateValue();

  // eslint-disable-next-line react/prop-types
  // const qData = data?.quiz;

  useEffect(() => {
    if (hostname.indexOf('telenor') > -1) {
      setTimeout(() => {
        window.location = 'https://telenor.no';
      }, 10000);
    } else if (hostname.indexOf('hurtigruten') > -1) {
      setTimeout(() => {
        window.location = 'https://www.hurtigruten.com/';
      }, 10000);
    } else if (hostname.indexOf('volmax') > -1) {
      console.log('should be verified now');
      // setTimeout(() => {
      //   window.location = 'https://volmax.no/';
      // }, 10000);
    } else {
      setTimeout(() => {
        window.location = 'https://www.evokedset.no/';
      }, 10000);
    }
  });
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* Facebook pixel verification */}
      <Helmet>
        {hostname && hostname.indexOf('volmax') > -1 && (
          <meta name="facebook-domain-verification" content="2ra6sajlhjunmwn86ok2vrfi9vmj5v" />
        )}
      </Helmet>
      <Spinner color="#ccc" />
    </div>
  );
}

export default Index;
